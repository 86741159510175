<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <herbicide-dike-statistics />
  </b-container>
</template>
<script>

import { core } from '@/config/pluginInit'
import HerbicideDikeStatistics from '../../components/dashboard/HerbicideDikeStatistics.vue'

export default {
  name: 'ManagerGraphHerbicideDike',
  middleware: ['auth', 'manager'],
  components: {
    HerbicideDikeStatistics
  },
  metaInfo () {
    return {
      title: 'Herbicides Dike Graph'
    }
  },

  mounted () {
    core.index()
  }

}
</script>
